import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from './privateRoute';

const MainLayout = Loadable(lazy(() => import('../layout/MainLayout')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CreerBatch = Loadable(lazy(() => import('../views/dashboard/Batch/CreerBatch')));
const Users = Loadable(lazy(() => import('views/dashboard/Users')));
const UpgradeAccount = Loadable(lazy(() => import('views/dashboard/Sales/upgradeAccount')));
const Advance = Loadable(lazy(() => import('views/dashboard/Sales/advance')));
const CashDeposit = Loadable(lazy(() => import('views/dashboard/Sales/cashDeposit')));

const MainRoutes = {
  path: '/',
  element: <PrivateRoute />, // Wrap the whole layout with PrivateRoute
  children: [
    {
      path: '',
      element: <MainLayout />,
      children: [
        {
          path: 'dashboard/Default',
          element: <DashboardDefault />
        },
        {
          path: 'dashboard/Batch',
          element: <CreerBatch />
        },{
          path:'dashboard/Users',
          element:<Users/>
        },
        {
          path:'dashboard/UpgradeAccount',
          element:<UpgradeAccount />
        },
        {
          path:'dashboard/advance',
          element:<Advance/>
        },
        
        {
          path:'dashboard/CashDeposit',
          element:<CashDeposit/>
        }
      ]
    }
  ]
};

export default MainRoutes;